<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 权限管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container" v-loading="loading">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-s-order" @click="handlAdd">添加角色</el-button>
      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="Name" label="角色" width="180"></el-table-column>
        <el-table-column prop="NewTime" label="更新日期"></el-table-column>
        <el-table-column prop="UpTime" label="添加日期"></el-table-column>
        <el-table-column width="150" label="操作" align="center">
          <template #default="scope">
            <el-button
                type="text"
                @click="handleEdit( scope.row)"
            >编辑
            </el-button>
            <el-button
                :disabled="scope.row.Name=='root'"
                type="text"
                class="red"
                @click="RemoveData( scope.row.id)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

    </div>

    <!-- 添加&更新角色-->
    <el-dialog title="操作" v-model="edit.view" width=" 1100px">

      <el-form ref="form" :model="form" label-width="120px" class="el-dialog-div">
        <el-form-item label="操作">
          <el-button @click="SelectAll" type="text">全选</el-button>
          <el-button @click="NoSelectAll" type="text">全不选</el-button>
        </el-form-item>
        <el-form-item label="角色名称" :required="true">
          <el-input v-model="form.Name" placeholder="请输入角色名称" :disabled="edit.type == '更新'"></el-input>
        </el-form-item>
        <el-form-item label="路由权限" :required="true">
          <div class="div" v-for="(item ,index) in RouterData" :key="index">
            <el-checkbox v-model="item.subs">{{ item.label }}</el-checkbox>
            <div class="children">
              <div v-for="(items,indexs) in item.children" :key="indexs">
                <el-checkbox v-model="items.subs">{{ items.label }}</el-checkbox>
              </div>
            </div>
          </div>
        </el-form-item>

      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="edit.view = false">取 消</el-button>
                    <el-button type="primary" @click="saveAdd">确 定</el-button>
                </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>

import {fetchData, fetchRouter, removeData, submitAdd, submitUp} from "../../api/System/Permission";


export default {
  data() {
    return {
      loading: false,
      query: {
        id: '',
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0
      },
      Router: [],
      RouterData: [],
      tableData: [],
      edit: {
        view: false,
      },
      form: {},
    };
  },
  created() {
    this.getRouterData()
    this.getData()
  },
  methods: {
    RemoveData(id) {
      this.$confirm("是否删除该权限？", "提示", {
        type: "warning"
      }).then(() => {
        removeData({id}).then(res => {
          if (res.code == 0) {
            this.$message.success(res.message);
            this.getData()
          } else {
            this.$message.error(res.message);
          }
        })
      })
    },
    SelectAll() {
      let RouterData = this.RouterData
      for (let item in RouterData) {
        RouterData[item].subs = true
        let children = RouterData[item].children
        for (let i in children) {
          children[i].subs = true
        }
        RouterData[item].children = children
      }
      this.RouterData = RouterData
    },
    NoSelectAll() {
      let RouterData = this.RouterData
      for (let item in RouterData) {
        RouterData[item].subs = false
        let children = RouterData[item].children
        for (let i in children) {
          children[i].subs = false
        }
        RouterData[item].children = children
      }
      this.RouterData = RouterData
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      let Router = JSON.parse(JSON.stringify(this.Router))
      let RoleRouter = JSON.parse(JSON.stringify(row.RoleRouter))

      //初始数据初始化
      for (let item in Router) {
        Router[item].subs = false
        let children = Router[item].children
        for (let items in children) {
          children[items].subs = false
        }
      }
      //数据处理 （开关）
      for (let k in RoleRouter) {
        let label = RoleRouter[k].label
        let children = RoleRouter[k].children
        for (let ks in Router) {
          if (Router[ks].label == label) {
            Router[ks].subs = RoleRouter[k].subs
            let RouterChind = Router[ks].children
            for (let k in RouterChind) {
              let labels = RouterChind[k].label
              for (let i in children) {
                if (labels == children[i].label) {
                  RouterChind[k].subs = children[i].subs
                }
              }
            }
            break;
          }
        }
      }


      let id = row.id
      this.query.id = id

      this.RouterData = Router
      this.edit.type = '更新'
      this.edit.view = true
    },
    getRouterData() {
      fetchRouter().then(res => {
        let data = res.data.RouterData
        this.RouterData = JSON.parse(JSON.stringify(data))
        this.Router = JSON.parse(JSON.stringify(data))
      });
    },
    getData() {
      this.loading = true
      fetchData().then(res => {
        this.loading = false
        this.tableData = res.sum;
      });
    },

    //打开添加角色面板
    handlAdd() {
      this.form = {}
      this.edit.type = '添加'
      this.edit.view = true
      this.NoSelectAll()
    },
    //添加角色名称&更新
    saveAdd() {
      let Name = this.form.Name
      let RouterData = this.RouterData
      if (Name) {
        if (this.edit.type == '添加') {
          submitAdd({Name, RouterData}).then(res => {
            if (res.code == 0) {
              this.$message.success(res.message);
              this.edit.view = false
              this.RouterData = JSON.parse(JSON.stringify(this.Router))
              this.form = {}
              this.getData()
            } else {
              this.$message.error(res.message);
            }
          })
        } else {
          let id = this.form.id
          submitUp({RouterData, id}).then(res => {
            if (res.code == 0) {
              this.$message.success(res.message);
              this.edit.view = false
              this.RouterData = this.Router
              this.form = {}
              this.getData()
            } else {
              this.$message.error(res.message);
            }
          })
        }
      } else {
        this.$message.error('缺少必填项');
      }
    }
  }
}
;
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.el-dialog-div {
  height: 600px;
  overflow: auto;
}

.red {
  color: red;
}


.children {
  margin-left: 30px;
}
</style>
