import request from '../../utils/request';

/**
 * 权限管理
 * @param query
 * @returns {AxiosPromise}
 */

//查询数据
export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'System/Permission/find',
        method: 'get',
        params: query
    });
};

//查询菜单
export const fetchRouter = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'System/Permission/find/Menu',
        method: 'get',
        params: query
    });
};

//添加权限
export const submitAdd = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'System/Permission/add',
        method: 'post',
        data: query
    });
};

export const submitUp = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'System/Permission/up',
        method: 'post',
        data: query
    });
};


//删除权限
export const removeData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'System/Permission/remove',
        method: 'post',
        data: query
    });
};

